<template>
    <registry-simple
      v-show="!isHidden"
      v-if="['registry', 'xref'].includes(settings.type)"
      :showVerticalLine="showVerticalLine"
      :pageLimit="pageLimit"
      :style="`height: ${height}`"
      :state-id="settings.stateId"
      :showButton="showButton"
      :state-params="stateParams"
      :default-group="defaultGroup"
      :show-search="settings.showSearch"
      :searchAttributes="settings.searchAttributes"
      @edit-record="editRecord"
      @open-card="addRecord"
      :mini="false"
      :headers="settings.columns"
      :cell-click-plugin="settings.cellClickPlugin"
      :row-double-clicked="rowDoubleClicked"
      ref="table"
      :id="settings.registryId"
      :xrefId="name"
      :styleTableHeader='styleTableHeader'
      :CSS="CSS"
      :treeTable="treeTable"
      :is-data-filter="true"
      :type="settings.type"
      :table-add-existing="tableAddExisting"
    ><slot></slot></registry-simple>
    <simple-table
      v-else
      v-show="!isHidden"
      :showButton="showButton"
      :style="`height: ${height}`"
      v-on:input="$emit('input', $event)"
      ref="table"
      @edit-record="openRecordEditor"
      :cell-click-plugin="settings.cellClickPlugin"
      :show-summary="settings.show_summary"
      :show-pagination="settings.show_pagination"
      :fast-add="settings.fast_add"
      :loader="this.loaderData"
      :columns="settings.columns"
      :is-selectable="settings.is_selectable"
      :styleTableHeader='styleTableHeader'
    ><slot></slot></simple-table>
</template>

<script>
import mixin from '../mixins'
import RegistrySimple from '@/components/Registry/RegistryTable.vue'
import SimpleTable from './a-simple-table.vue'
import VisibleMixin from '../visible_properties_mixin'
import RegistryCard from '@/components/RegistryCard/index.vue'
import FilterBuilder, { EComponentTypes } from '../utils'
export default {
  name: 'a-table',
  components: {
    RegistrySimple,
    SimpleTable,
    RegistryCard
  },
  inject: {
    openRegistryCard: { default: () => {} },
    openDashboardCard: { default: () => {} },
    forceUpdateSettingsPanel: { default: () => () => {} },
    $computedShowCard: { default: () => () => {} }
  },
  mixins: [mixin, VisibleMixin],
  props: {
    value: {
      frozen: true
    },
    editorAlias: {
      type: String,
      description: 'Псевдоним'
    },
    name: {
      type: String,
      description: 'Атрибут',
      options: {
        removeSpaces: true
      }
    },
    settings: {
      type: Object,
      default: () => {
        return {
          type: 'registry',
          registryId: null,
          columns: [],
          is_selectable: true,
          stateId: null,
          plugin: null,
          fast_add: false,
          show_pagination: true,
          show_summary: false,
          filters: [],
          editor: null,
          searchAttributes: null,
          showSearch: false,
          cellClickPlugin: null,
          xrefId: null
        }
      },
      editor: 'Table'
    },
    filters: {
      type: Array,
      editor: 'Filters'
    },
    styleTableHeader: {
      type: String,
      description: 'CSS стили шапки таблицы',
      default: () => {
        return 'font-style: normal; font-weight: normal; font-size: 13px; line-height: 20px; word-break: break-word; color: #807265'
      }
    },
    pageLimit: {
      type: Number,
      description: 'Количество записей на странице'
    },
    defaultGroup: {
      type: String,
      description: 'Группировка (attr_N_)',
      options: {
        removeSpaces: true
      }
    },
    showButton: {
      type: Object,
      default: () => {
        return {
          update: false,
          add: false,
          add_existing: false,
          delete: false,
          export: false,
          import: false,
          views: false,
          group: false,
          filter: false,
          edit: false
        }
      },
      editor: 'ShowButton',
      description: 'Видимость кнопок'
    },
    extendedToBottom: {
      type: Boolean,
      description: 'Растягивать вниз',
      default: true
    },
    showVerticalLine: {
      type: Boolean,
      description: 'Убрать вертикальные линии'
    },
    treeTable: {
      type: Boolean,
      description: 'Древовидная таблица'
    },
    alwaysActive: {
      type: Boolean,
      description: 'Всегда активно'
    },
    defaultCardId: {
      type: [Object, Number],
      description: 'Карточка',
      editor: 'Cards',
      default: () => {
        return {
          cardId: null,
          isWindow: false,
          windowTitle: '',
          windowWidth: '25'
        }
      }
    },
    defaults: {
      type: Array,
      editor: 'Filters',
      options: {
        title: 'По умолчанию',
        tooltip: 'Значения новой записи по умолчанию',
        showXrefOption: false,
        showEqualsTypes: false
      },
      default: () => {
        return []
      }
    },
    rowDoubleClicked: {
      type: Boolean,
      description: 'Открытие двойным кликом'
    },
    tableAddExisting: {
      type: Object,
      default: () => ({}),
      editor: 'TableAddExisting'
    }
  },
  provide () {
    return {
      aTableScope: this
    }
  },
  data () {
    return {
      card: null,
      rendered: false,
      observer: undefined,
      loading: false,
      positionScrolInTable: 0
    }
  },
  computed: {
    height () {
      if (this.extendedToBottom && !this.isHidden && this.rendered) {
        return 'calc(100% - ' + this.$el.offsetTop + 'px)'
      }
      return '100%'
    },
    xrefIds () {
      let xrefIds = this.getModel()[this.name]

      if (!xrefIds) {
        return []
      }

      if (typeof xrefIds === 'number' || Number(xrefIds) == xrefIds) {
        return [Number(xrefIds)]
      }

      if (typeof xrefIds === 'string') {
        try {
          xrefIds = JSON.parse(xrefIds)
        } catch (error) {
          console.error(error)
        }
      }

      if (Array.isArray(xrefIds)) {
        return xrefIds.map(xrefId => Number(xrefId.id || xrefId))
      }

      return []
    },
    dataFilters () {
      const builder = new FilterBuilder(
        this.filters,
        this.getModel(),
        this.$store,
        EComponentTypes.table
      )

      const filters = builder.buildAsQueryParams()

      if (this.settings.type === 'xref') {
        // Фильтр по ID записей
        if (this.xrefIds.length > 0) {
          filters.push(`id,eqx,${this.xrefIds}`)
        } else {
          filters.push('id,eqx,0')
        }
      }

      // if (this.filters) {
      //   this.filters.forEach(item => {
      //     let type = 'eq'
      //     if (item.isXref) {
      //       type = 'eqx'
      //     }
      //     if (!item.type || item.type === 'field') {
      //       if (this.getModel()[item.attribute] && item.alias && this.getModel()[item.attribute] + '') {
      //         filters.push(`${item.alias},${type},${this.getModel()[item.attribute]}`)
      //       }
      //     } else if (item.type === 'constant' && item.alias) {
      //       filters.push(`${item.alias},${type},${item.attribute}`)
      //     } else if (item.type === 'current_user' && item.alias) {
      //       filters.push(`${item.alias},${type},${this.$store.getters['Authorization/userId']}`)
      //     }
      //   })
      // }
      return filters
    },
    loaderData () {
      let filters = []
      if (this.settings.filters) {
        this.settings.filters.forEach(item => {
          if (this.getModel()[item.attribute] && item.alias) {
            filters.push(`${item.alias}=${this.getModel()[item.attribute]}`)
          }
        })
      }
      let loader = dataFilter => {
        if (this.settings.plugin) {
          return this.$http.get(`${this.$config.api}/registryservice/plugins/execute/${this.settings.plugin}?${dataFilter.join('&')}`)
        } else if (this.value) {
          return { data: this.value }
        }
        return null
      }

      return () => loader(filters)
    },
    stateParams () {
      let params = {};
      (this.settings.stateParams || []).forEach((item) => {
        if (!item.type || item.type === 'field') {
          if (this.getModel()[item.attribute] && item.alias) {
            this.$set(params, item.alias, this.getModel()[item.attribute])
          }
        } else if (item.type === 'constant' && item.alias) {
          this.$set(params, item.alias, item.attribute)
        } else if (item.type === 'current_user') {
          this.$set(params, item.alias, this.$store.getters['Authorization/userId'])
        }
      })
      return params
    },
    computedShowCard () {
      return this.$computedShowCard()
    }
  },
  watch: {
    computedShowCard: {
      handler: function (val) {
        // принудительно обновить таблицу после закрытия карточки (баг слетает внешний вид таблицы)
        if (!val) {
          setTimeout(() => {
            let container = this.$refs.table?.$el.querySelector('.el-table__body-wrapper')
            if (container) {
              container.scrollTo({ top: this.positionScrolInTable, left: 0, behavior: 'instant' })
              this.positionScrolInTable = 0
            }
          }, 300)
        }
      }
    },
    dataFilters () {
      if (['registry', 'xref'].includes(this.settings.type)) {
        setTimeout(() => {
          if (!this.loading) {
            this.loadData()
          }
        }, 700)
      }
    },
    stateParams: {
      async handler () {
        if (['registry', 'xref'].includes(this.settings.type)) {
          setTimeout(() => {
            if (!this.loading) {
              this.loadData()
            }
          }, 700)
        }
      }
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  created () {
    if (['registry', 'xref'].includes(this.settings.type) && this.settings.registryId) {
      this.parseColumnHiddenConditions(this.settings.columns)
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.rendered = true
      let me = this
      let respondToVisibility = function (element, callback) {
        let options = {
          root: document.documentElement
        }

        me.observer = new IntersectionObserver((entries, observer) => {
          entries.forEach(entry => {
            callback(entry.intersectionRatio > 0)
          })
        }, options)

        me.observer.observe(element)
      }
      respondToVisibility(this.$el, visible => {
        if (visible) {
          if (this.$refs.table) {
            setTimeout(() => this.$refs.table.$refs.table.doLayout(), 100)
          }
        }
      })
      if (this.$refs.table) {
        this.$refs.table.$refs.table.doLayout()
      }
    })

    if (['registry', 'xref'].includes(this.settings.type) && this.settings.registryId) {
      this.$http.get(`${this.$config.api}/interfaceeditor/cards?entity_id=${this.settings.registryId}&fields=id,name,is_default`)
        .then(response => {
          let card = response.data.filter(item => item.is_default)
          this.card = card[0]
          setTimeout(() => {
            if (!this.loading) {
              this.loadData()
            }
          }, 700)
        })
    }
  },
  beforeDestroy () {
    this.observer.unobserve(this.$el)
  },
  methods: {
    // скрытие столбцов по условию
    parseColumnHiddenConditions (columns) {
      if (!Array.isArray(columns)) {
        return
      }

      columns.forEach((column) => {
        if ((column.hidden_condition || '').trim().length > 0) {
          let condition = JSON.parse(JSON.stringify(column.hidden_condition))
          let attributes = condition.match(/\{(.*?)\}/g)
          if (attributes) {
            attributes.forEach((attribute) => {
              attribute = attribute.replace('{', '').replace('}', '')
              let value = this.getModel()[attribute]
              try {
                value = JSON.parse(value)
              } catch (e) {

              }
              if (value instanceof Array) {
                value = value.map(item => item.id || item).join(',')
              }
              condition = condition.replace(`{${attribute}}`, value)
            })
          }

          try {
            if (eval(condition)) {
              column.hidden = true
            } else {
              column.hidden = false
            }
          } catch (e) {
            console.info(`invalid condition: ${column.hidden_condition}, result: ${condition}`)
          }
        }
        if ((column.children || []).length > 0) {
          this.parseColumnHiddenConditions(column.children)
        }
        if ((column.children || []).length > 0 && (column.children || []).filter(item => item.hidden).length === (column.children || []).length) {
          column.hidden = true
        }
      })
    },
    getDefaultsForCard () {
      let defaults = []
      if (this.defaults) {
        this.defaults.forEach((item) => {
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute] && item.alias) {
              defaults.push({
                key: item.alias,
                value: this.getModel()[item.attribute]
              })
            }
          } else if (item.type === 'constant' && item.alias) {
            defaults.push({
              key: item.alias,
              value: item.attribute
            })
          } else if (item.type === 'current_user') {
            defaults.push({
              key: item.alias,
              value: this.$store.getters['Authorization/userId']
            })
          }
        })
      }
      return defaults
    },
    async loadData () {
      this.loading = true
      this.$refs.table.clearFilters()
      this.dataFilters.forEach(item => {
        this.$refs.table.addFilter(item)
      })

      if (this.settings.type === 'xref') {
        // Показывать также удалённые записи
        this.$refs.table.isIncludingDeleted = true
      }

      await this.$refs.table.loadData()
      this.loading = false
    },
    openRecordEditor (data) {
      if (!data.id) {
        console.warn('bad id parameter')
      }
      this.openDashboardCard(this.settings.editor, 'test', data.id, data)
    },
    async getRegistryCardId (recordId) {
      let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.settings.registryId}/records/${recordId}/card`)
      return data.data[0]
    },
    async editRecord (data) {
      let containter = this.$refs.table?.$el.querySelector('.el-table__body-wrapper')?.scrollTop
      if (containter) {
        this.positionScrolInTable = containter
      }
      let card = {}
      if (this.defaultCardId.cardId) {
        card = {
          id: this.defaultCardId.cardId
        }
      } else {
        card = this.card
        if (data.id) {
          this.$set(data, 'loading', true)
          card = await this.getRegistryCardId(data.id)
          this.$set(data, 'loading', false)
        }
      }
      if (!card) {
        return false
      }
      this.openRegistryCard({
        registryId: this.settings.registryId,
        cardId: card.id,
        cardName: card.name,
        recordId: data.id,
        initialData: {},
        registry: this.$refs.table
      })
    },
    addRecord () {
      let card = {}
      if (this.defaultCardId.cardId) {
        card = {
          id: this.defaultCardId.cardId
        }
      } else {
        card = this.card
      }
      if (!card) {
        return false
      }
      let initialData = {}
      let defaults = this.getDefaultsForCard()
      defaults.forEach((item) => {
        initialData[item.key] = item.value
      })
      if (this.defaultCardId.isWindow) {
        const h = this.$createElement
        let customClass = 'custom_scrollbar '
        if (this.defaultCardId.windowWidth) {
          customClass += `dashboard_window_width_${this.defaultCardId.windowWidth}`
        }
        let me = this
        this.$msgbox({
          title: me.defaultCardId.windowTitle,
          customClass: customClass,
          message: h('registry-card', { style: {
            height: me.defaultCardId.windowHeight || ''
          },
          props: {
            cardId: card.id,
            registryId: me.settings.registryId,
            recordId: null,
            initialData: initialData
          },
          on: {
            cancelChanges: function () {
              me.$msgbox.close()
            },
            addRecord: function () {
              me.$refs.table.loadData()
            }
          },
          key: this.generateGuid()
          }),
          showCancelButton: false,
          showConfirmButton: false,
          closeOnClickModal: false
        })
      } else {
        this.openRegistryCard({
          registryId: this.settings.registryId,
          cardId: card.id,
          cardName: card.name,
          recordId: null,
          initialData: initialData,
          registry: {
            addRecord: (id) => {
              if (this.settings.type == 'xref') {
                this.addMultiLink(id)
              }
              this.$refs.table.loadData()
            },
            updateRecord: () => {}
          }
        })
      }
    },
    addMultiLink (id) {
      const xrefAttrKey = this.name
      // Записи мн. ссылки в карточке
      const modelXref = this.getModel()[xrefAttrKey] || []
      if (!this.getModel()[xrefAttrKey]) {
        console.log(`${xrefAttrKey} не найден в карточке при добавлении записи`)
      }
      // Объединить ID записей
      let newXrefIds = [...modelXref, id]
      // Убрать дубли
      newXrefIds = [...new Set(newXrefIds)]

      /* Изменить model (не перетирая массив, чтобы не сбилась ссылка) */
      // Очистить массив
      modelXref.splice(0, modelXref.length)
      modelXref.push(...newXrefIds)
    },
    async refreshComponents (components) {
      if (typeof components !== 'undefined' && components.length > 0) {
        let componentsAttrs = []
        components.forEach((component) => {
          let vueComponent = this.getDashboardComponents()[`component_${component}`]

          if (typeof vueComponent !== 'undefined' && typeof vueComponent[0] !== 'undefined') {
            if (typeof vueComponent[0].loadData === 'function') {
              vueComponent[0].loadData()
            } else if (vueComponent[0].$refs.hasOwnProperty('table')) {
              vueComponent[0].$refs.table.$refs.tableBody.$refs.grid.load()
            } else if (vueComponent[0].$refs.hasOwnProperty('grid')) {
              vueComponent[0].$refs.grid.load()
            }

            if (this.getDashboardComponents()[`component_${component}`][0].hasOwnProperty('source')) {
              componentsAttrs.push(this.getDashboardComponents()[`component_${component}`][0].source.valueField.name)
            } else {
              componentsAttrs.push(this.getDashboardComponents()[`component_${component}`][0].name)
            }
          }
        })

        if (this.$attrs?.type !== 'dashboard') {
          let card = typeof this.getCard === 'function' ? this.getCard() : null
          if (card && typeof card !== 'undefined') {
            if (typeof card.registryId !== 'undefined' && typeof card.recordData.id !== 'undefined') {
              let response = await this.$http.get(`${this.$config.api}/registryservice/registry/${card.registryId}/card/${card.recordData.id}?card_id=${card.cardId}`)
              if (response) {
                componentsAttrs.forEach((component) => {
                  this.$set(this.getModel(), component, response.data[component])
                })
              }
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
